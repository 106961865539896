import { Typography, Grid, Avatar, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import moment from 'moment';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InstagramRectIconButton from 'components/Planner/components/InstagramRectIconButton';
import CategoryChip from 'components/Planner/components/CategoryContainer/components/CategoryChip';
import { InfluencerUpdateProfile } from 'api_handlers/discovery';
import React, { useState, useEffect } from 'react';
import CountDisplay from 'components/ux/CountDisplay';
import { withStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#3A3A3A",
        color: "white",
        borderRadius: "2px",
        maxWidth: 220,
        width: 120,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
    arrow: {
        color: "#3A3A3A",
    },
}))(Tooltip);

function BasicInfo({
  name,
  picture,
  interests = [],
  url,
  audience_short_desc = {},
  platform = 'instagram',
  prediction,
  primary_category = '',
  last_updated,
  id,
  influencer,
  is_staff,
  update_status,
  isClicked,
  updateStatus,
}) {
  // let inf="2024-03-13T18:31:57.479700+05:30"
  // console.log('last_updated',last_updated!==undefined?last_updated:0)
  // // let currentUpdateTime=localStorage.getItem(`${name}`)
  // const updatedTime=new Date(last_updated!==undefined?last_updated:0).getTime()/1000+(((23*3600)+(59*60)+(59)))
  // console.log('updatedTime',updatedTime)

  // const currentTime=new Date().getTime()/1000
  // console.log('comparetime',currentTime<=updatedTime)

  // let newUpdateTime = 0
  // // const newCurrentTime = new Date(currentTime*1000)
  // let timeLeft = 0
  // let secondLeft=0
  // let minutesleft=0
  // let hoursLeft=0

  // if(currentTime<=updatedTime ){
  //   newUpdateTime=new Date(updatedTime*1000)
  //   timeLeft =(newUpdateTime-currentTime*1000)-19800000
  //   secondLeft=new Date(timeLeft).getSeconds()
  //   minutesleft=new Date(timeLeft).getMinutes()
  //   hoursLeft=new Date(timeLeft).getHours()

  // console.log("secondLeft",secondLeft)
  // console.log("minutesleft",minutesleft)
  // console.log("hoursLeft",hoursLeft)
  // console.log("timeLeft",timeLeft)

  // }

  // const [minutes, setMinutes ] = useState(minutesleft);
  // const [seconds, setSeconds ] =  useState(secondLeft);
  // const [hours,setHours] = useState(hoursLeft)
  const [isDisabled, setIsDisabled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const classes = useStyles();
  const date = last_updated !== undefined ? new Date(last_updated) : null;

  function handleApi() {
    if (!isDisabled) {
      setIsUpdating(true);
      const res = InfluencerUpdateProfile(id, influencer, platform);
      res.then((responce) => {
        if ([200, 201].includes(responce.status)) {
          // setIsUpdating(false)
          isClicked(true);
        }
      });
      // InfluencerUpdateProfile(id,influencer,platform)
      // setIsDisabled(true)
    }
  }

  useEffect(() => {
    setIsUpdating(updateStatus);
  }, [updateStatus]);

  // console.log("Date: ", date)
  // useEffect(()=>{
  //   let myInterval = setInterval(() => {
  //     // console.log("seconds",seconds)
  //     // console.log("minutes",minutes)
  //     // console.log("hours",hours)

  //     if(hours >= 0 && minutes >= 0 && seconds>0 ){
  //       setSeconds(seconds - 1);
  //     }
  //      if(hours >= 0 && minutes > 0 && seconds===0 ){
  //       setMinutes(minutes - 1);
  //       setSeconds(59);
  //     } if(hours > 0 && minutes === 0 && seconds===0 )
  //     {
  //       setHours(hours - 1);
  //       setMinutes(59);
  //       setSeconds(59)
  //     }
  //      if(hours === 0 && minutes === 0 && seconds===0){
  //       clearInterval(myInterval)
  //     }
  //   }, 1000)
  //   if(currentTime>=updatedTime || (hoursLeft === 0 && minutesleft === 0 && secondLeft===0) ){
  //     setIsDisabled(false)
  //   }
  // return ()=> {
  //     clearInterval(myInterval);
  //   }
  // })

  // function refreshApi(){
  //   return
  // }

  // useEffect(() => {
  //   const lastUpdated = date;
  //   const currentTime = new Date();
  //   const timeDifference = currentTime - lastUpdated;
  //   const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

  //   if (hoursDifference >= 24) {
  //     setIsDisabled(false);
  //   } else {
  //     setIsDisabled(true);
  //   }
  // }, [last_updated, isDisabled]);

  return (
    <Grid container className={classes.root}>
      <Grid item md={8}>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          <Grid item md={'auto'} className={classes.avatarContainer}>
            <Avatar alt={name} src={picture} className={classes.avatar} />
          </Grid>
          <Grid item md={8}>
            <Grid justifyContent="start" container style={{ marginBottom: 20 }}>
              <Grid item style={{ marginRight: 30 }}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <Typography variant="body1">{name}</Typography>
                      </Grid>
                      <Grid item style={{ marginLeft: 15 }}>
                        <InstagramRectIconButton
                          onClick={() => {
                            window.open(url, '_blank');
                          }}
                          platform={platform}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item style={{ textAlign: 'start' }}>
                    <Typography variant="subtitle2">
                      {prediction?.creator_type[0]?.toUpperCase() +
                        prediction?.creator_type.slice(1).toLowerCase()}{' '}
                      Influencer
                    </Typography>
                  </Grid>
                  {is_staff ? (
                    <Grid item>
                      <Grid container style={{ marginTop: 5 }}>
                        <Grid item md={2}>
                          <AutorenewIcon
                            style={{
                              color: isDisabled ? '#666666' : '#ff8800',
                              height: 18,
                            }}
                          ></AutorenewIcon>
                        </Grid>
                        <Grid item md={10}>
                          {update_status !== 'failed' ? (
                            <Grid container>
                              {!isUpdating ? (
                                <Grid item>
                                  <Grid container>
                                    <Grid item>
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          cursor: isDisabled ? '' : 'pointer',
                                          color: isDisabled ? '#666666' : '#ff8800',
                                          fontSize: 13,
                                          marginLeft: 2,
                                        }}
                                        onClick={() => {
                                          handleApi();
                                        }}
                                      >
                                        Update profile
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      {isDisabled && update_status === 'updated' && !isUpdating ? (
                                        <Grid item style={{ maxHeight: 10, marginTop: '-85%' }}>
                                          <CountDisplay
                                            tooltipInfo={
                                              <React.Fragment>
                                                <div>
                                                  <b>
                                                    Wait untill 24 hours
                                                    {/* { minutes == 0 && seconds === 0 && hours === 0
                                                          ? null
                                                          : <div>{hours < 10 ?  `0${hours}` : hours}:{minutes < 10 ?  `0${minutes}` : minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</div> 
                                                      } */}
                                                  </b>
                                                </div>
                                              </React.Fragment>
                                            }
                                          ></CountDisplay>
                                        </Grid>
                                      ) : (
                                        ''
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid item>
                                  <Grid container spacing={4} alignItems="center">
                                    <Grid item>
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          cursor: isDisabled ? '' : 'pointer',
                                          color: isDisabled ? '#666666' : '#ff8800',
                                          fontSize: 13,
                                          marginLeft: 2,
                                        }}
                                        //   onClick={()=>{
                                        //   handleApi()
                                        // }}
                                      >
                                        Updating...
                                      </Typography>
                                    </Grid>
                                    <Grid>
                                      <CircularProgress
                                        style={{ height: 15, width: 15, color: '#ff8800' }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                      {update_status == 'failed' ? (
                        <Grid container style={{ marginTop: '-13%', marginLeft: '11%' }}>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                cursor: isDisabled ? '' : 'pointer',
                                color: isDisabled ? '#666666' : '#ff8800',
                                fontSize: 13,
                                marginLeft: 2,
                                position: 'relative',
                                zIndex: 2,
                              }}
                              onClick={() => {
                                handleApi();
                              }}
                            >
                              Update profile
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid item style={{ maxHeight: 0, position: 'relative', left: '50%' }}>
                              <HtmlTooltip title="Profile Updating failed">
                                <span style={{ cursor: 'pointer' }}>
                                  <InfoOutlinedIcon fontSize="small" />
                                </span>
                              </HtmlTooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  ) : (
                    ''
                  )}

                  <Grid item>
                    {
                      <Grid container>
                        <Grid item>
                          <Typography style={{ fontSize: 14, marginLeft: 2, color: '#444444' }}>
                            Updated :
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography style={{ fontSize: 14, marginLeft: 5, color: '#444444' }}>
                            {' '}
                            {moment(date).format('DD-MM-yyyy HH:mm')}{' '}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid container>
              <CategoryChip label={primary_category} color="#FC2E2E" borderColor="#FC2E2E" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3}>
        <Grid container className={classes.audienceInfoBase}>
          <Grid item md={12} className={classes.audienceInfo}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h3" style={{ color: 'white', fontWeight: 600 }}>
                  Audience Info
                </Typography>
              </Grid>
              <Grid item>
                <TrendingUpIcon />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 10 }}>
              <Grid item md={5}>
                <Grid container justifyContent="center" alignItems="center" direction="column">
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row "
                    >
                      <Grid item md={'auto'}>
                        <LocationOnIcon style={{ fontSize: '1rem' }} />
                      </Grid>
                      <Grid item md={'auto'}>
                        <Typography
                          style={{
                            color: 'white',
                            fontWeight: 600,
                            fontSize: '0.95rem',
                          }}
                        >
                          {audience_short_desc?.location?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h3"
                      style={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: 18,
                        paddingTop: '2px',
                      }}
                    >
                      {audience_short_desc?.location?.percentage}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Grid container justifyContent="center" alignItems="center" direction="column">
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row "
                    >
                      <Grid item md={'auto'}>
                        {audience_short_desc?.gender?.code?.charAt(0) == 'M' ? (
                          <ManIcon style={{ fontSize: '1rem' }} />
                        ) : (
                          <WomanIcon style={{ fontSize: '1rem' }} />
                        )}
                      </Grid>
                      <Grid item md={'auto'}>
                        <Typography
                          style={{
                            color: 'white',
                            fontWeight: 600,
                            fontSize: '0.95rem',
                          }}
                        >
                          {audience_short_desc?.gender?.code?.charAt(0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h3"
                      style={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: 18,
                        paddingTop: '2px',
                      }}
                    >
                      {audience_short_desc?.gender?.percentage}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Grid container justifyContent="center" alignItems="center" direction="column">
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item md={'auto'}>
                        <Typography
                          style={{
                            color: 'white',
                            fontWeight: 600,
                            fontSize: '0.95rem',
                          }}
                        >
                          {`${audience_short_desc?.age?.code} yrs`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h3"
                      style={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: 18,
                        paddingTop: 2,
                      }}
                      mt={2}
                    >
                      {audience_short_desc?.age?.percentage}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarContainer: {
    background: '#EFF0F9',
    boxShadow:
      '-5px 5px 10px rgba(203, 204, 212, 0.2), 5px -5px 10px rgba(203, 204, 212, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(203, 204, 212, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(203, 204, 212, 0.5)',
    borderRadius: '50%',
    padding: 10,
  },
  avatar: {
    filter: 'drop-shadow(0px 5px 10px rgba(141, 141, 141, 0.25))',
    height: 150,
    width: 150,
  },
  audienceInfoBase: {
    background:
      'linear-gradient(118.02deg, rgba(239, 26, 116, 0.57) 32.63%, rgba(254, 189, 28, 0.63) 94.6%)',
    borderRadius: 10,
    height: 150,
  },
  audienceInfo: {
    background: 'rgba(239, 240, 249, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(27.2667px)',
    borderRadius: 10,
    color: 'white',
    padding: '10px 20px',
  },
}));

export default BasicInfo;
