import React from 'react';
import { IconButton, Box, Popper, Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';

const styles = () => ({
  root: {},
  button: {
    right: 20,
    bottom: 20,
    position: 'fixed',
    color: 'white',
    background: '#9317a7',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
});

class BaseFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      anchorEl: null,
    };
  }

  handleClick(event) {
    console.log('event', event);
    this.setState({
      anchorEl: event.target,
      popupOpen: !this.state.popupOpen,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Box sx={{ width: 500 }} className={classes.root}>
        <Popper
          open={this.state.popupOpen}
          anchorEl={this.state.anchorEl}
          placement={'top-end'}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                width={300}
                style={{
                  padding: '30px 30px 0px 30px',
                  background: 'white',
                  borderRadius: 4,
                }}
              >
                {this.props.children}
              </Box>
            </Fade>
          )}
        </Popper>
        <IconButton
          onClick={(event) => {
            this.handleClick(event);
          }}
          className={classes.button}
        >
          <FilterListIcon fontSize="large" />
        </IconButton>
      </Box>
    );
  }
}

export default withStyles(styles)(BaseFilter);
