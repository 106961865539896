import React, { useState, useEffect } from 'react';

import {Grid} from '@mui/material';

import { gridSpacing } from 'store/constant';

import InfluencerList from '../InfluencerList';

import { getPlannerInfluencersES } from 'api_handlers/es';
import { fetchCuratedInfluencers } from 'api_handlers/planner2';


const CuratedInfluencers = ({match: { params }}) => {
  const [influencers, setInfluencers] = useState([]);

  const { curatedItemId } = params;

  const fetchInfluencers = async (page) => {
    const response = await getPlannerInfluencersES(
      {
        "campaign-level-requirements": {},
        "creator-level-requirements": {},
        "advance-filters": {},
        "setReset": false
      }, page);
    const {results} = await response.json();
    setInfluencers(results);
  }

  const updateFilters = async () => {}

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    fetchCuratedInfluencers(curatedItemId).then((response)=>{
      setInfluencers(response.data.map(profile=>{
        return profile
      }));
    });
    // fetchInfluencers(1)
  }
  , []);

    return (
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={12}>
          <InfluencerList
            influencers={influencers}
            fetchInfluencers={fetchInfluencers}
            totalResults={influencers.length}
            updateFilters={updateFilters}
          />
        </Grid>
      </Grid>
    )
}

export default CuratedInfluencers;