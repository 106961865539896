import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Grid } from '@material-ui/core';
// import { RadialChart, DiscreteColorLegend } from "react-vis";
import { Doughnut } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoDataLabel from '../common/NoDataLabel';

import { getYoutubeGenderGraphData } from '../../../../api_handlers/reporting';

class GenderSplitupGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  async getData() {
    const resp = await getYoutubeGenderGraphData(this.props.campaignId);
    this.setState({ data: resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      <Card>
        <CardContent>
          <Grid container>
            <Grid
              item
              md={12}
              class="dashboard-card-title"
              style={{
                marginBottom: '15px',
              }}
            >
              Gender Splitup
            </Grid>
            <Grid item md={12}>
              <Grid container>
                <Grid item md={12} style={{ height: 200, width: 200 }}>
                  {this.state.loading ? (
                    <CircularProgress style={{ marginTop: 50 }} />
                  ) : this.state.data.length === 0 ? (
                    <NoDataLabel offsetTop={50} />
                  ) : (
                    <div
                      style={{
                        height: 200,
                        width: 200,
                        margin: '0 auto',
                      }}
                    >
                      <Doughnut
                        data={{
                          labels: ['Male', 'Female'],
                          datasets: [
                            {
                              label: '# of Votes',
                              data: this.state.data,
                              backgroundColor: ['#008adf', '#ff7be5'],
                              borderColor: ['#008adf', '#ff7be5'],
                              borderWidth: 1,
                            },
                          ],
                          options: {
                            plugins: {
                              legend: {
                                display: true,
                                labels: {
                                  position: 'right',
                                },
                              },
                            },
                            aspectRatio: 1,
                          },
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(GenderSplitupGraph));
