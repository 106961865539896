import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import AppRouter from './Routes';
import store from './store';

function App() {
  return (
    <BrowserRouter forceRefresh={true}>
      <div className="App">
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
