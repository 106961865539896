import { constants } from '../constants/planner-filters';

const initialState = {
  sortBy: 'followers',
  platform: 'instagram',
};

const PlannerFilters = (state = initialState, action) => {
  switch (action.type) {
    case constants.UPDATE:
      const newFilters = {...state};
      const {name, value} = action.data
      newFilters[name] = value;
      return newFilters;
    case constants.RESET:
      return initialState;
    default:
      return state;
  }
}

export default PlannerFilters;
