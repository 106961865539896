import { Component } from 'react';
import { Card, CardContent, Grid, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import { campaignHandlers } from '../../../api_handlers/campaign';

import NoDataLabel from '../../reporting/cards/common/NoDataLabel';
import Campaign from './Campaign';

class CampaignListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
    };
  }

  componentDidMount() {
    campaignHandlers.getCampaigns().then((data) => {
      this.setState({
        campaigns: data.slice(0, 4),
      });
    });
  }

  render() {
    return (
      <Card style={{ height: '520px' }}>
        <CardContent style={{ padding: '7px 0px' }}>
          <Grid container>
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{
                  fontSize: '12px',
                }}
              >
                <Grid
                  item
                  md={4}
                  class="dashboard-card-title"
                  style={{ margin: '7px 0px 7px 10px' }}
                >
                  Recent Campaigns
                </Grid>
                <Grid
                  item
                  md={7}
                  style={{
                    fontSize: '11px',
                    fontWeight: '500',
                    marginTop: '9px',
                  }}
                ></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              style={{
                overflowY: 'scroll',
                height: '500px',
                background: '#f1f1f1',
              }}
            >
              {this.state.loading ? (
                <CircularProgress style={{ marginTop: 150 }} />
              ) : this.state.campaigns.length === 0 ? (
                <NoDataLabel offsetTop={160} />
              ) : (
                this.state.campaigns
                  .filter((campaign) => {
                    return campaign.id !== parseInt(this.props.campaignId);
                  })
                  .map((campaign, i) => {
                    return <Campaign {...campaign} />;
                  })
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  campaignId: state.campaign.campaignId,
});

export default connect(mapStateToProps)(CampaignListCard);
