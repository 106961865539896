import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AvatarGroup from '@mui/material/AvatarGroup';

import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';
import { ThemeMode } from 'config';



const CuratedCard = ({ platform, name, background_img, category, id, pictures }) => {
    const theme = useTheme();
    let history = useHistory()

    return (
        <Card
            sx={{
                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.50',
                border: '1px solid',
                borderColor: 'divider',
                textAlign: 'center',
                cursor: 'pointer'
            }}
            onClick={() => {
              history.push(`/planner/curated-list/${id}`);
            }}
        >
            <CardMedia
                component="img"
                image={background_img || "https://png.pngtree.com/thumb_back/fh260/background/20230408/pngtree-rainbow-curves-abstract-colorful-background-image_2164067.jpg"}
                title="Slider5 image"
                sx={{ height: '125px' }}
            />
            <CardContent sx={{ p: 2, pb: '16px !important' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Avatar
                                    alt={name}
                                    src={platform === "instagram" ? "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png" : "https://cdn-icons-png.flaticon.com/256/1384/1384060.png"}
                                    sx={{ width: 72, height: 72, m: '-50px auto 0' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h4">{name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">{category}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 2 }} justifyContent="center">
                    <AvatarGroup max={4}>
                        {
                            pictures.map((each, index)=>{
                                return <Avatar alt={`User ${index}`} src={each} />
                            })
                        }
                    </AvatarGroup>
                </Grid>
            </CardContent>
        </Card>
    );
};

CuratedCard.propTypes = {
    avatar: PropTypes.string,
    name: PropTypes.string,
    background: PropTypes.string,
    category: PropTypes.string,
    status: PropTypes.string
};

export default CuratedCard;
