import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './customRoutes/ProtectedRoutes';

import { ThemeProvider } from '@material-ui/core';
import theme from './theme';

import InstagramCallback from './components/auth/InstagramCallback';
import AwardBannerPage from './components/award/BannerPage';
import BrandRoutes from './components/brands/Routes';
import InfluencerRoutes from './components/influencers/Routes';
import ComingSoon from './components/ux/ComingSoon';
import LoginWrapper from './pages/LoginPage/LoginWrapper';
import MobilePriceIndexWrapper from './pages/PriceIndex/MobilePriceIndexWrapper';
import MobilePriceIndexDemoWrapper from './pages/PriceIndexDemo/MobilePriceIndexDemoWrapper';

import Planner from 'components/Planner';
import PlannerV2 from 'components/PlannerV2/Index.jsx';

import CompetitorsInsights from './components/CompetitorsInsights/Index';
import YTDetailInsights from 'components/DetailedInsights/ytDetailedInsights';
import InstagramDetailInsights from 'components/DetailedInsights/DetailedInsights';
import Discovery from './components/Discovery';
import CompetitorReportAnalysis from 'components/CompetitorReportAnalysis';
import CompetitorReportAnalysisUI from 'components/CompetitorReportAnalysisUI';
import InfluencerInsight from 'components/PlannerV2/components/InfluencerInsight';

import LoginSignupV2 from 'components/LoginSignupV2/Index.jsx';


class AppRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={'/'}>
          <Redirect to="/login" />
        </Route>
        <Route exact path={'/login'} component={LoginSignupV2} />
        <Route exact path={'/signup'} component={LoginSignupV2} />
        {/* <Route exact path={'/login'} component={LoginWrapper} /> */}
        <Route exact path={'/login/creators'} component={LoginWrapper} />
        <Route exact path={'/login/brands'} component={LoginWrapper} />
        <Route exact path={'/signup/creators'} component={LoginWrapper} />
        <Route exact path={'/signup/brands'} component={LoginWrapper} />
        <Route exact path={'/planner/profile/:platform/:handle'} component={InfluencerInsight}></Route>
        <Route exact path={'/instagram/callback/'} component={InstagramCallback} />
        <PrivateRoute path={'/brand'} component={BrandRoutes} />
        <PrivateRoute path={'/influencer'} component={InfluencerRoutes} />
        <Route exact path={'/comingsoon'} component={ComingSoon} />
        <Route exact path={'/creator-awards'} component={AwardBannerPage} />
        <Route exact path={'/past'} component={ComingSoon} />
        <Route exact path={'/price-index'} component={MobilePriceIndexWrapper} />
        <Route exact path={'/price-index-demo'} component={MobilePriceIndexDemoWrapper} />
        <Route
          exact
          path={'/discovery'}
          render={() => (
            <ThemeProvider theme={theme}>
              <Discovery />
            </ThemeProvider>
          )}
        />
        <PrivateRoute exact path={'/planner'}>
          <Redirect to="/planner/discover" />
        </PrivateRoute>
        <PrivateRoute path={'/planner'} component={PlannerV2} />
        <PrivateRoute path={'/planner-v1'} component={Planner} />
        <PrivateRoute
          exact
          path={'/competitor-insights/:brand_id/:keyword/youtube/detailed-insights'}
          component={YTDetailInsights}
        />
        <PrivateRoute
          exact
          path={'/competitor-insights/:brand_id/:keyword/instagram/detailed-insights'}
          component={InstagramDetailInsights}
        />
        <PrivateRoute path={'/competitor-insights/:brand_id/:keyword?'} component={CompetitorsInsights} />
        <PrivateRoute path={'/competitor-insights/'} component={CompetitorsInsights} />
        <PrivateRoute path={'/competitor-report-analysis/:brand_id'} component={CompetitorReportAnalysis}/>
        <PrivateRoute path={'/competitor-report-analysis-ui/:brand_id'} component={CompetitorReportAnalysisUI}/>
      </Switch>
    );
  }
  componentDidMount() {
    document.title = 'Influencer.in';
  }
}

export default AppRouter;
