import { constants } from "constants/planner-filters";
import { fetchInfluencersAction, updatePredictionStatus } from "actions/planner2";
// import { setAutoCompleteValues } from "actions/planner2";


export const updateFilters = (name, value, requiredResultUpdate) => {
  if (name === 'platform' && !value) {
    value = 'instagram';
  }
  return (dispatch, getState) => {
    dispatch(update());

    if (requiredResultUpdate)
      setTimeout(() => {
        dispatch(fetchInfluencersAction(1));
      }, 200);

  };

  function update() {
    return {
      type: constants.UPDATE,
      data: {name: name, value: value},
    }
  }
}

export const resetFilters = () => {

  return (dispatch) => {
    dispatch(reset());
    dispatch(updatePredictionStatus(null));
  }

  function reset() {
    return {type: constants.RESET}
  }
}