import { Route, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import React from 'react';

// checkAuth checks if the token is currently valid only.
// while you are sitting on a page for a while, the token might expire.
const checkAuth = () => {
  const token = localStorage.getItem('access');
  if (!token || token === 'undefined') {
    localStorage.clear();
    return false;
  }

  const tokenExpiration = jwtDecode(token).exp;
  const currentTime = Math.round(new Date().getTime() / 1000);
  const timeLeft = tokenExpiration - currentTime;

  if (tokenExpiration && timeLeft <= 0) {
    localStorage.clear();
    return false;
  }
  return true;
};

const PrivateRoute = ({ component: Component, ...restProps }) => {
  return (
    <Route
      {...restProps}
      render={(props) => {
        return checkAuth() ? (
          <Component {...props} {...restProps} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        );
      }}
    />
  );
};

export { PrivateRoute };
