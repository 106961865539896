import { constants } from '../constants/planner2';

import { getPlannerInfluencersES, getPlannerPredictionES, getPlannerRecommendedInfluencersES } from 'api_handlers/es';
import { getCurrentUser } from 'api_handlers/auth';

export const updatePage = (page) => {
  return {type: constants.UPDATE_PAGE,
    page,
  }
}

export const updatePayload = (payload) => {
  return {type: constants.UPDATE_PAYLOAD,
    payload,
  }
}

export const addFilterInPayload = (name, value, requiredResultUpdate) => {
  return (dispatch, getState) => {
    const state = getState().planner2;
    const {payload} = state;
    const newPayload = {...payload};
    
    const keys = constants.PAYLOAD_PATH_MAPPING[name].split('.');
    let current = newPayload;

    for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
            current[keys[i]] = {};
        }
        current = current[keys[i]];
    }

    const lastKey = keys[keys.length - 1];

    if (value === null) {
        delete current[lastKey];
    } else {
        current[lastKey] = value;
    }
    
    dispatch(updatePayload(newPayload));
    
    if (requiredResultUpdate)
      dispatch(fetchInfluencersAction(1, newPayload));
    
  }
}

export const convertFilterInPayload = (state) => {
  const appliedFilters = {...state.plannerFilters};
  const payload = {
    "campaign-level-requirements": {},
    "creator-level-requirements": {
      "platform": "instagram"
    },
    "advance-filters": {
      "sortOrder": "desc",
    },
    "setReset": false
  }

  if(appliedFilters.audienceGenderSliderValue && appliedFilters.audienceGenderSelectValue) {
    appliedFilters.audienceGenderSplit = `${appliedFilters.audienceGenderSelectValue},${appliedFilters.audienceGenderSliderValue}`;
  }

  Object.keys(appliedFilters).forEach(name => {
    if ([
      'audienceGenderSliderValue',
      'audienceGenderSelectValue'
    ].includes(name)) {
      return;
    }
    const value = appliedFilters[name];
    const path = constants.PAYLOAD_PATH_MAPPING[name];

    if(!path) return;

    const keys = path.split('.');
    let current = payload;

    for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
            current[keys[i]] = {};
        }
        current = current[keys[i]];
    }

    const lastKey = keys[keys.length - 1];

    if (value === null) return;

    if(Array.isArray(value) && name !== 'ids') {
      current[lastKey] = value.join(',');
      if(name === 'locations') current[lastKey] = current[lastKey].toLowerCase();
    }
    // if(Array.isArray(value) && name !== 'ids') {
    //   current[lastKey] = value.join(',');
    // }
    else current[lastKey] = value;

  })

  const budget = payload["campaign-level-requirements"]["budget"];
  // if(budget && budget > 0 && !payload["creator-level-requirements"]["creator-type"]){
  //   payload["creator-level-requirements"]["creator-type"] = { "macro": budget };
  // }
  if(budget && budget > 0 && !payload["campaign-level-requirements"]["price-per-creator"]){
    payload["campaign-level-requirements"]["price-per-creator"] = 0.1 * budget;
  }
  
  if (!payload['campaign-level-requirements']['price-per-creator'])
    payload['campaign-level-requirements']['price-per-creator'] = 10000000000;
  
  return payload;
}

export const updateInfluencers = (influencers) => {
  return {type: constants.UPDATE_INFLUENCERS,
    influencers,
  }
}

export const updateTotalInfluencersCount = (totalInfluencersCount) => {
  return {type: constants.TOTAL_INFLUENCERS,
    totalInfluencersCount,
  }

}

export const toggleDrawerOpen = () => {
  return (dispatch) => {
    dispatch(update());
  };

  function update() {
    return {type: constants.TOGGLE_DRAWER_OPEN};
  }
}

export const setLoading = (loading) => {
  return {type: constants.SET_LOADING,
    loading,
  }
}


export const fetchInfluencersAction = (page, payload) => {
  return (dispatch, getState) => {
    const state = getState().planner2;
    let requestPayload;
    if(page === 1) requestPayload = payload || convertFilterInPayload(getState());
    else requestPayload = state.payload;

    const budget = requestPayload["campaign-level-requirements"]["budget"];
    
    if (state.loading) return;
    dispatch(setLoading(true));

    console.log("state, newInfluencersSearch", state.newInfluencersSearch)

    if(state.newInfluencersSearch.length > 0) {
      dispatch(fetchAndUpdateNewInfluencers(1));
      return;
    }

    let fetchFunction
    if (budget && budget > 0) fetchFunction = getPlannerRecommendedInfluencersES;
    else fetchFunction = getPlannerInfluencersES;
    
    fetchFunction(requestPayload, page).then(response => {
      return response.json();
    }).then(data => {
      const {results, recommendation, count, payload} = data;
      

      if((budget && budget > 0) || (requestPayload.searchedSelectedInfluencerIds)) {
        dispatch(updateInfluencers([...recommendation]));
      } else {
        dispatch(updateInfluencers([...results]));
      }

      if(budget && budget > 0 && page === 1) {
        dispatch(updatePrediction(requestPayload));
      }
      
      dispatch(setLoading(false));
      dispatch(updatePayload(payload))
      dispatch(updatePage(page));

      if(page === 1) {
        dispatch(updateTotalInfluencersCount(count));
      }

    });

  }
};

export const updateSelectedInfluencers = (influencers) => {
  return {
    type: constants.UPDATE_SELECTED_INFLUENCERS,
    selectedInfluencers: influencers,
  }
}

export const updateUser = () => {
  return (dispatch) => {
    getCurrentUser().then((response) => {
      dispatch(update(response.data));
    });
  }

  function update(user) {
    return {type: constants.UPDATE_USER,
      user,
    }
  }  
}

export const showPremiumFeatureDialog = (value) => {
  return {
    type: constants.SHOW_PREMIUM_FEATURE_DIALOG,
    showPremiumFeatureDialog: value,
  }
}

export const updatePrediction = (payload) => {
  return (dispatch, getState) => {
    const state = getState().planner2;
    const requestPayload = payload || state.payload;
    dispatch(updatePredictionStatus("loading"));

    getPlannerPredictionES(requestPayload).then(response => {
      return response.json();
    }).then(data => {
      dispatch(updatePredictions(data));
      dispatch(updatePredictionStatus("loaded"));
    });

  }
}

export const updatePredictionStatus = (status) => {
  return {
    type: constants.UPDATE_PREDICTION_STATUS,
    status,
  }
}

export const updatePredictions = (predictions) => {
  return {
    type: constants.UPDATE_PREDICTIONS,
    predictions,
  }
}

export const resetSearch = () => {
  return (dispatch) => {
    const payload = constants.DEFAULT_PAYLOAD;
    dispatch(updatePayload(payload));
    dispatch(fetchInfluencersAction(1, payload));
  }
}

export const addNewInfluencer = (data) => {
  return {
    type: constants.ADD_NEW_INFLUENCER,
    data,
  }
}

export const updateNewInfluencerSearch = (data) => {
  return {
    type: constants.UPDATE_NEW_INFLUENCER_SEARCH,
    data,
  }
}

const fetchAndUpdateNewInfluencers = (retry=1) => {
  console.log("fetchAndUpdateNewInfluencers", retry)

  // Handle New Profile
  return (dispatch, getState) => {
    if(retry > 20) {
      dispatch(updateInfluencers([]));
      dispatch(setLoading(false));
      dispatch(updateTotalInfluencersCount(0));
      return
    }

    const state = getState().planner2;
  
    const newInfluencerToAdd = []
    state.newInfluencers.forEach(influencer => {
      if (state.newInfluencersSearch.includes(influencer.link)) {
        newInfluencerToAdd.push(influencer);
      }
    });


    if(newInfluencerToAdd.length !== state.newInfluencersSearch.length) {
      setTimeout(() => dispatch(fetchAndUpdateNewInfluencers(retry + 1)), 2000)
    } else {
      const newInfluencers = newInfluencerToAdd
        .filter(inf => inf.status === "success")
        .map(inf => inf.data)
      dispatch(updateInfluencers([...newInfluencers]));
      dispatch(setLoading(false));
      dispatch(updateTotalInfluencersCount(newInfluencers.length));
    }
  }
}

export const setAutoCompleteValues = (autoCompleteValues) => {
  return {
    type: constants.UPDATE_AUTO_COMPLETE_VALUES,
    autoCompleteValues,
  }
}