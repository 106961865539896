import React, { useEffect } from 'react';

// material-ui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

import CuratedCard from './CuratedCard';

// assets
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import { getFieldOfInterest } from 'api_handlers/influencer';
import { fetchCuratedList } from 'api_handlers/planner2';

const CuratedList = () => {
    const [curatedItems, setCuratedItems] = React.useState([])
    const [search, setSearch] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [platform, setPlatform] = React.useState('instagram');
    const [category, setCategory] = React.useState(null);
    const [categoryOptions, setCategoryOptions] = React.useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleSearch = async (event) => {
        const newString = event?.target.value;
        setSearch(newString);

        if (newString) {
            // dispatch(filterProfileCards(newString));
        } else {
            // dispatch(getProfileCards());
        }
    };

    useEffect(() => {
        fetchCuratedList(platform, category).then((response)=>{
            setCuratedItems(Object.keys(response.curated_lists).map(key=>{
                let data = response.curated_lists[key];
                data['name'] = key;
                return data;
            }))
        });
    //   setCuratedItems([
    //     ...users
    //         .filter((user) => user.name.toLowerCase().includes(search.toLowerCase()))
    //         .filter((user) => platform ? user.platform === platform : true)
    //         .filter((user) => category ? user.category === category.label : true)
    //   ])
    }, [search, platform, category]);

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const data = await getFieldOfInterest();
        setCategoryOptions([...data.map((item) => ({ label: item.name, value: item.id }))]);
    }, []);

    return (
        <MainCard
            sx={{ 
                '& .MuiCardHeader-root': {
                    height: 70
                }
             }}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Curated List</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Autocomplete
                                    size="small"
                                    options={categoryOptions}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => <TextField sx={{p:0, minWidth: 180}} {...params} label="Category"/>}
                                    onChange={(_, value) => {
                                        setCategory(value ? value.label : null)}
                                    }
                                    limitTags={1}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ m: 1, minWidth: 180 }} size='small'>
                                    <InputLabel id="platform-select">Platform</InputLabel>
                                    <Select
                                        labelId="platform-select"
                                        id="platform"
                                        name="platform"
                                        value={platform}
                                        onChange={(event) => setPlatform(event.target.value)}
                                        label="Platform"
                                    >
                                        <MenuItem value={null}>All Platform</MenuItem>
                                        <MenuItem value={'instagram'}>Instagram</MenuItem>
                                        <MenuItem value={'youtube'}>YouTube</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                            <FormControl sx={{ m: 1, minWidth: 100 }} size='small'>
                                <OutlinedInput
                                    id="input-search-card-style3"
                                    placeholder="Search"
                                    value={search}
                                    onChange={handleSearch}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon stroke={1.5} size="16px" />
                                        </InputAdornment>
                                    }
                                    size="small"
                                />
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            }
        >
            <Grid container direction="row" spacing={gridSpacing}>
                {curatedItems.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} lg={4} xl={3}>
                        <CuratedCard
                          {...item}
                        />
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination count={10} color="primary" />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                size="large"
                                sx={{ color: 'grey.900' }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                            >
                                10 Rows
                            </Button>
                            <Menu
                                id="menu-user-card-style3"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={handleClose}> 10 Rows</MenuItem>
                                <MenuItem onClick={handleClose}> 20 Rows</MenuItem>
                                <MenuItem onClick={handleClose}> 30 Rows </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default CuratedList;
