import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { USE_ES } from 'config';

import { Card, CardContent, Grid, Divider } from '@material-ui/core';
// import {XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries} from 'react-vis';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Line } from 'react-chartjs-2';

import NoDataLabel from '../common/NoDataLabel';

import { getYoutubeEngagmentGraphData } from '../../../../api_handlers/reporting';
import { getYoutubeEngagmentGraphDataES } from 'api_handlers/es';


class EngagementGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  async getData() {
    const fetchFunction = USE_ES ? getYoutubeEngagmentGraphDataES : getYoutubeEngagmentGraphData;

    const resp = await fetchFunction(this.props.campaignId);
    this.setState({ data: resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      <Card>
        <CardContent>
          <Grid container>
            <Grid item md={12} class="dashboard-card-title">
              Values over time
            </Grid>
            <Grid item md={12} style={{ height: 237}}>
              {this.state.loading ? (
                <CircularProgress style={{ marginTop: 90 }} />
              ) : this.state.data.length === 0 ? (
                <NoDataLabel offsetTop={90} />
              ) : (
                <Line
                  height={"180%"}
                  width={700}
                  data={{
                    labels: this.state.data.labels,
                    datasets: [
                      {
                        label: 'Engagement Rate',
                        data: this.state.data.engagement_rate,
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgb(255, 99, 132)',
                      },
                      {
                        label: 'View',
                        data: this.state.data.views,
                        fill: false,
                        backgroundColor: 'rgb(54, 162, 235)',
                        borderColor: 'rgb(54, 162, 235)',
                      },
                      {
                        label: 'Likes',
                        data: this.state.data.likes,
                        fill: false,
                        backgroundColor: 'rgb(255, 206, 86)',
                        borderColor: 'rgb(255, 206, 86)',
                      },
                      {
                        label: 'Comments',
                        data: this.state.data.comments,
                        fill: false,
                        backgroundColor: 'rgb(75, 192, 192)',
                        borderColor: 'rgb(75, 192, 192)',
                      },
                    ],
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(EngagementGraph));
