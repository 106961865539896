/* global BigInt */
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Grid } from '@material-ui/core';

import moment from 'moment';

import CountDisplay from '../../../ux/CountDisplay';
import { isBgmi } from 'components/utils';

// import ViewIcon from '@material-ui/icons/VisibilityOutlined';
// import LikeIcon from '@material-ui/icons/ThumbUpOutlined';
// import CommentIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import logo from '../../../../assets/youtube-logo.png';
import ViewIcon from '../../../../assets/users_group.png';
import Thumbs_UpIcon from '../../../../assets/Thumbs_Up.png';
import CommentIcon from '../../../../assets/Comment.png';
import EngagementIcon from '../../../../assets/Engagement.png';
import EngagementRateIcon from '../../../../assets/EngagementRate.png';

import { getYoutubeStatistic } from '../../../../api_handlers/reporting';

import CircularProgress from '@material-ui/core/CircularProgress';

class Statistics extends Component {
  constructor(props) {
    super(props);
  }

  // async getData(){
  //     const resp = await getYoutubeStatistic(
  //         this.props.campaignId,
  //         this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD'): null,
  //         this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null
  //     )
  //     this.setState({...resp.data, loading: false})
  // }

  // componentDidMount(){
  //     setTimeout(()=>{
  //         this.getData()
  //     }, 30)
  // }

  // componentDidUpdate(prevProps){
  //     console.log("props diff", prevProps, this.props)
  //     if(prevProps.startDate !== this.props.startDate && prevProps.endDate !== this.props.endDate)
  //         this.getData()
  // }

  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const is_gamezy = user.user_type === 'brand' ? (user.brand.id == 80 ? true : false) : false;
    return (
      <Card>
        <CardContent>
          <Grid container>
            <Grid
              item
              md={12}
              style={{
                marginBottom: (this.props.extraMargin | 0) + 10,
              }}
            >
              <Grid container justify="space-between">
                <Grid item md={4}>
                  <Grid container alignItems="center">
                    <Grid item md={2}>
                      <img src={logo} height={'29'} />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      style={{
                        fontSize: '13px',
                        fontWeight: 500,
                      }}
                    >
                      Statistics
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    fontSize: '12px',
                    lineHeight: '14px',
                    textAlign: 'right',
                    color: '#5C5C5C',
                  }}
                >
                  {this.props.startDate && this.props.endDate
                    ? `${moment(this.props.startDate).format('DD/MM/YY')} - ${moment(
                        this.props.endDate,
                      ).format('DD/MM/YY')}`
                    : 'Overall data'}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              {!this.props.stats ? (
                <CircularProgress />
              ) : (
                <Grid container spacing={0} justify="space-around">
                  <Grid container spacing={0} style={{justifyContent: 'space-around', paddingBottom: '2%'}}>
                  <Grid item md={2}>
                    <CountDisplay
                      icon={ViewIcon}
                      label={'Total View'}
                      count={this.props.stats ? this.props.stats.total_views : 0}
                      color={'#7158F1'}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <CountDisplay
                      icon={Thumbs_UpIcon}
                      label={'Total Likes'}
                      count={(this.props.stats && this.props.stats.total_likes) || 0}
                      color={'#B563DC'}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <CountDisplay
                      icon={CommentIcon}
                      label={'Total Comments'}
                      count={(this.props.stats && this.props.stats.total_comments) || 0}
                      color={'#E78615'}
                    />
                  </Grid>
                  {is_gamezy ? (
                    <Grid item md={2}>
                      <CountDisplay
                        icon={EngagementIcon}
                        label={'Total Estimated Watch Minute'}
                        count={
                          (this.props.stats && this.props.stats.total_estimatedminuteswatched) || 0
                        }
                        color={'#1582E7'}
                      />
                    </Grid>
                  ) : isBgmi(user) ? (
                    <Grid item md={2}>
                      <CountDisplay
                        icon={EngagementIcon}
                        label={'Total Impressions'}
                        count={this.props.stats ? this.props.stats.total_impressions : 0}
                        color={'#1582E7'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  </Grid>
                  <Grid container spacing={0} style={{justifyContent: 'flex-start'}}>
                  {isBgmi(user) ? (
                    <Grid item md={2} style={{marginLeft: '3.4%'}}>
                      <CountDisplay
                        icon={EngagementRateIcon}
                        label={'Engagement Rate'}
                        count={(this.props.stats && this.props.stats.engagement_rate) || 0}
                        color={'#0CBD8C'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item md={2} style={{marginLeft: '9.4%'}}>
                      <CountDisplay
                        icon={CommentIcon}
                        label={'Live Videos'}
                        count={(this.props.stats && this.props.stats.total_videos) || 0}
                        color={'#0CBD8C'}
                      />
                    </Grid>
                    <Grid item md={2} style={{marginLeft: '8.1%'}}>
                      <CountDisplay
                        icon={EngagementRateIcon}
                        label={`Influencer's live count`}
                        multiCount={`${this.props.stats.live_influencers_count}/${this.props.stats.shortlisted_influencer_count}`}
                        color={'#0CBD8C'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(Statistics));
