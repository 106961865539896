import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Grid } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoDataLabel from '../common/NoDataLabel';

import { getYoutubeAgeGraphData } from '../../../../api_handlers/reporting';

class AgeSplitupGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  async getData() {
    const resp = await getYoutubeAgeGraphData(this.props.campaignId);
    this.setState({ data: resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      <Card>
        <CardContent>
          <Grid container>
            <Grid item md={12} class="dashboard-card-title">
              Viewers Age Splitup
            </Grid>
            <Grid item md={12} style={{ height: 215, margin: 'auto auto' }}>
              {this.state.loading ? (
                <CircularProgress style={{ marginTop: 80 }} />
              ) : this.state.data.length === 0 ? (
                <NoDataLabel offsetTop={90} />
              ) : (
                <Bar
                  style={{
                    position: 'relative',
                    top: '35px',
                  }}
                  width={700}
                  data={{
                    labels: this.state.data.labels,
                    datasets: [
                      {
                        label: 'Age',
                        data: this.state.data.dataset,
                        backgroundColor: ['rgba(255, 99, 132, 1)'],
                      },
                    ],
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(AgeSplitupGraph));
