import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { authHandlers } from 'api_handlers/auth';
import YoutubeLogin from 'components/auth/YoutubeLogin';


const AuthLogin = ({ goToDiscover, showDomainInvalidModal, ...others }) => {
  const theme = useTheme();

  const login = (e) => {
    e.preventDefault();
    setErrors('');
    setEmailError(false);
    setPasswordError(false);
    let isValidationError = false;
    if (!(email && email.length > 0)) {
        setEmailError(true);
        isValidationError = true;
    }
    if (!(password && password.length > 0)) {
        setPasswordError(true);
        isValidationError = true;
    }
    if (isValidationError) {
      return;
    }
    setIsSubmitting(true);
    const resp = authHandlers.login({
      email: email,
      password: password,
      user_type: 'brand',
    });

    resp.then(async (response) => {
        setIsSubmitting(false);
        if ([200, 201].includes(response.status)) {
            const userData = await response.json();
            localStorage.setItem('access', userData.access);
            localStorage.setItem('refresh', userData.refresh);
            const user = {
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            user_type: userData.user_type,
            influencer: userData.influencer,
            brand: userData.brand,
            };
            localStorage.setItem('user', JSON.stringify(user));
            goToDiscover();
        } else if ([400, 401, 402, 403].includes(response.status)) {
            const errors = await response.json();
            setErrors(errors);
        } else {
          setErrors({ 'non_field_errors': ['Please Try Again'] });
        }
    });
  }

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [errors, setErrors] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const registerToBackendYoutube = async(data) => {
    const resp_data = await authHandlers.youtubeBrandLogin(data);
    if (resp_data.error && resp_data.error === 'Please provide valid brand email') {
      showDomainInvalidModal();
    } else if (resp_data.user) {
      goToDiscover();
    } else {
      setErrors({ 'non_field_errors': ['Please Try Again'] });
    }
  }

  return (
    <>
      <form noValidate onSubmit={login}>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={emailError}>
            <InputLabel htmlFor="outlined-adornment-email-login">Work Email Address</InputLabel>
            <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                name="email"
                label="Email Address"
                inputProps={{}}
                onChange={(e)=>{
                    let value = e.target.value;
                    setEmailError(false);
                    if (!(value && value.length > 0)) {
                        setEmailError(true);
                    }
                    setEmail(value);
                }}
                />
            {emailError && (
                <FormHelperText error id="standard-weight-helper-text-email-login">
                Please Enter Valid Email
                </FormHelperText>
            )}
            </FormControl>
            <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={passwordError}>
              <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-password-login"
                type={showPassword ? 'text' : 'password'}
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                inputProps={{}}
                onChange={(e)=>{
                    let value = e.target.value;
                    setPasswordError(false);
                    if (!(value && value.length > 0)) {
                        setPasswordError(true);
                    }
                    setPassword(value);
                }}
              />
            {passwordError && (
                <FormHelperText error id="standard-weight-helper-text-email-login">
                Please Enter Valid Password
                </FormHelperText>
            )}
            </FormControl>
            {
                errors ? 
                <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{'non_field_errors' in errors ? errors['non_field_errors'].map(each=>{
                        return each;
                    }) :
                    <ul>
                    {Object.keys(errors).map((key) => {
                      const error = errors[key];
                      if (Array.isArray(error)) {
                        return (
                          <li style={{ fontSize: 12 }}>
                            {error}
                          </li>
                        );
                      } else {
                        return (
                          <li style={{ fontSize: 12 }}>
                            {Object.values(error).join(',')}
                          </li>
                        );
                      }
                    })}
                  </ul>}</FormHelperText>
                </Box> : null
            }
            <Box sx={{ mt: 2 }}>
                <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="secondary">
                    Sign in
                </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              Or
            </Box>
            <YoutubeLogin
                label="Sign-in with Google"
                onSuccess={(response) => {
                  registerToBackendYoutube(response);
                }}
              />
      </form>
    </>
  );
};

export default AuthLogin;