import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// project import
import { ThemeMode } from 'config';

// assets
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';


const CampaignCard = ({ name, start_date, added, handleAddToCampaign, id }) => {
    const theme = useTheme();
    

    return (
        <Card
            sx={{
                p: 2,
                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.50',
                border: '1px solid',
                borderColor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'grey.100',
                '&:hover': { borderColor: 'primary.main' }
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {/* <Grid item>
                            <Avatar alt="User 1" src={avatar && getImageUrl(`${avatar}`, ImagePath.USERS)} />
                        </Grid> */}
                        <Grid item xs zeroMinWidth>
                            <Typography
                                variant="h5"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}
                            >
                                {name}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{ mt: 0.25, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}
                            >
                                <CalendarMonthIcon sx={{ mr: '6px', fontSize: '16px', verticalAlign: 'text-top' }} />
                                {start_date}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {added ? (
                        <Button variant="contained" fullWidth startIcon={<PersonAddTwoToneIcon />}>
                            Added
                        </Button>
                    ) : (
                        <Button variant="outlined" fullWidth startIcon={<PeopleAltTwoToneIcon />} onClick={() => {
                            handleAddToCampaign(id);
                        }}>
                            Add
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Card>
    );
};

CampaignCard.propTypes = {
    avatar: PropTypes.string,
    follow: PropTypes.number,
    location: PropTypes.string,
    name: PropTypes.string
};

export default CampaignCard;
