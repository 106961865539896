import * as React from 'react';
import { borderRadius } from '@material-ui/system';
import { Padding } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import Iframe from 'react-iframe';
import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getIdViaChannelYt, getIdViaHandler } from 'api_handlers/discovery';
import { getDiscoveryInfluencersDetails } from 'api_handlers/planner2';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function InsightFrame() {
  const platform = window.location.pathname.split('/')[3];
  const influencer_handler = window.location.pathname.split('/')[4];
  const classes = useStyle();
  const [getId, setGetId] = useState(null);
  const [updateStatus, setUpdateStatus] = useState('');

  const fetchDetails = async () => {
    if (getId) {
      getDiscoveryInfluencersDetails(getId).then((response) => {
        if (response?.update_status === 'updating') {
          setUpdateStatus('updating');
        }
        if (response?.update_status === 'updated') {
          setUpdateStatus('updated');
        }
      });
    }
  };

  if (!getId) {
    if (platform === 'youtube') {
      getIdViaChannelYt(influencer_handler).then((res) => {
        setGetId(res.data.id);
      });
    } else if (platform === 'instagram') {
      getIdViaHandler(influencer_handler).then((res) => {
        setGetId(res.data.id);
      });
    }
  }

  useEffect(() => {
    fetchDetails();
  }, [getId]);

  return (
    <Grid container>
      <Grid item md={12}>
        <Iframe
          url={`/planner/profile/${platform}/${influencer_handler}`}
          width="100%"
          height="770px"
          frameBorder="0"
          className={classes.frameStyle}
        ></Iframe>
        {
          updateStatus ? 
          <React.Fragment>
          <BootstrapDialog
            onClose={()=>{
              setUpdateStatus('')
            }}
            aria-labelledby="customized-dialog-title"
            open={updateStatus === 'updating'}
            fullWidth
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={()=>{
                setUpdateStatus('')
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              We are updating the data for this profile, Please come back later
            </DialogContent>
          </BootstrapDialog>
          </React.Fragment>
          : null
        }
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => ({
  frameStyle: {
    
  },
}));
