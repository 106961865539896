import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { ThemeMode } from 'config';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme } from '@material-ui/core/styles';
// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        color: "white",
        borderRadius: "5px",
        width: 120,
        height:50,
        fontSize: theme.typography.pxToRem(12),
        fontSize:10
    },
    arrow: {
        color: "#3A3A3A",
    },
}))(Tooltip);

function LinearProgressWithLabel({ value, ...others }) {
  const theme = useTheme();

  return (
    <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                color: theme.palette.mode === ThemeMode.DARK ? 'dark.light' : 'secondary.main',
              }}
            >
              Credits used
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="inherit">{`${Math.round(value)}%`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <LinearProgress
          aria-label="progress of theme"
          variant="determinate"
          value={value}
          {...others}
          sx={{
            height: 10,
            borderRadius: 30,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              bgcolor: 'background.paper',
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 5,
              bgcolor: 'secondary.main',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number,
};

// ==============================|| SIDEBAR - MENU CARD ||============================== //

const MenuCard = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.planner2.user);

  const percentage = useMemo(() => {
    if (!user) return 0;
    const total = user.premium_user ? 200 : 5;

    return (user.credit_balance / total) * 100;
  }, [user]);

  return (
    <Card
      sx={{
        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'grey.400',
        mb: 2.75,
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          width: 157,
          height: 157,
          bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.dark' : 'secondary.main',
          borderRadius: '50%',
          top: -105,
          right: -96,
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <List disablePadding sx={{ m: 0, pb: 3 }}>
          <ListItem alignItems="flex-start" disableGutters disablePadding>
            <ListItemAvatar sx={{ mt: 0 }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.largeAvatar,
                  color: 'secondary.main',
                  border: theme.palette.mode === ThemeMode.DARK ? '1px solid' : 'none',
                  borderColor: 'secondary.main',
                  bgcolor: 'background.paper',
                }}
              >
                <TableChartOutlinedIcon fontSize="inherit" color="secondary.main" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ mt: 0 }}
              primary={
                <Grid container alignItems='center'>
                  <Grid item>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{
                        color:
                          theme.palette.mode === ThemeMode.DARK ? 'dark.light' : 'secondary.main',
                      }}
                    >
                      Credit balance
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: 5, color: '#676767', marginBottom:-6}}>
                    <HtmlTooltip title="1 Credit = 1 Profile unlock with audience insights" style={{fontSize:1}}>
                      <Typography style={{ cursor: 'pointer' }}>
                        <ErrorIcon fontSize="small"/>
                      </Typography>
                    </HtmlTooltip>
                  </Grid>
                </Grid>
              }
              secondary={
                <Typography variant="h5" fontWeight="bold">
                  {user?.credit_balance}
                </Typography>
              }
            />
          </ListItem>
        </List>
        {/* <LinearProgressWithLabel value={percentage} /> */}
      </Box>
    </Card>
  );
};

export default memo(MenuCard);
