import React, { Component } from 'react';
import {Box} from '@mui/material';
import { connect } from 'react-redux';
import qs from 'qs';

import ThemeCustomization from 'themes';
import { createTheme } from '@mui/material/styles';

import 'assets/scss/style.scss';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import Footer from 'components/auth/Footer';

import { authHandlers } from '../../api_handlers/auth';
import ErrorDisplay from '../influencers/forms/ErrorDisplay';
import Login from './Login';
import Signup from './Signup';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const theme = createTheme();


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


class LoginSignupV2 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      login: true,
      user_type: 'brand',
      passwordOk: true,
      showDomainInvalid: false
    }
  }

  componentDidMount() {
    if (
      localStorage.getItem('access') &&
      JSON.parse(localStorage.getItem('user')).user_type == 'brand'
    ) {
      this.props.history.push('/planner/discover');
    } else if (
      localStorage.getItem('access') &&
      JSON.parse(localStorage.getItem('user')).user_type == 'influencer'
    ) {
      this.props.history.push('/influencer');
    }

    const queryParams = qs.parse(this.props.location.search.replace('?', ''));
    if (queryParams.t === 'brand-signup') {
      this.setState({
        login: false,
      });
    }
    if (queryParams.t === 'brand-login') {
      this.setState({
        login: true,
      });
    }
    const url_attr = this.props.location.pathname.split('/');
    if (url_attr[2] != 'undefined') {
      if (url_attr[1] === 'signup') {
        this.setState({
          login: false,
        });
      }
      if (url_attr[1] === 'login') {
        this.setState({
          login: true,
        });
      }
    }
  }

  toggleLogin = () => {
    if (this.state.login) {
      this.props.history.push('/signup');
    } else {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <ThemeCustomization>
        {
          this.state.login ? <Login 
          showDomainInvalidModal={()=>{
            this.setState({ showDomainInvalid: true });
          }}
          toggleLogin={this.toggleLogin}
          goToDiscover={()=>{
            this.props.history.push('/planner/discover');
          }}/> : <Signup 
          showDomainInvalidModal={()=>{
            this.setState({ showDomainInvalid: true });
          }}
          toggleLogin={this.toggleLogin}
          goToDiscover={()=>{
            this.props.history.push('/planner/discover');
          }}/>
        }
        {
          this.state.showDomainInvalid ? 
          <React.Fragment>
          <BootstrapDialog
            onClose={()=>{
              this.setState({ showDomainInvalid: false });
            }}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={()=>{
                this.setState({ showDomainInvalid: false });
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers style={{ textAlign: 'center' }}>
              Oops! There seems to be an issue with your email's domain. Do write to us at Enquiry@influencer.in and we will get this working for you right away.
            </DialogContent>
          </BootstrapDialog>
          </React.Fragment>
          : null
        }
      </ThemeCustomization>
    );
  }
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {})(LoginSignupV2);