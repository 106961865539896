import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

import { gridSpacing } from 'store/constant';
import { getCampaigns, addInfluencerToCampaign } from 'api_handlers/campaign';

import CampaignCard from './CampaignCard';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export default function AdvancedFilters({ open, setOpen, influencers }) {
  const [campaigns, setCampaigns] = React.useState([]);
  const [addedInCampaigns, setAddedInCampaigns] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    const resp = await getCampaigns();
    setCampaigns(resp);
  }, []);

  const handleAddToCampaign = async (campaignId) => {
    const {data} = await addInfluencerToCampaign(campaignId, {
      profiles: influencers.map((influencer) => influencer.id),
    })

    if (data.success) {
      setAddedInCampaigns((prevValue) => [...prevValue, campaignId]);
    }

  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add To Campaign
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container direction="row" spacing={gridSpacing}>
            {campaigns.map((campaign, index) => (
              <Grid key={index} item xs={12} sm={6} md={6} lg={6}>
                <CampaignCard
                  {...campaign}
                  added={addedInCampaigns.includes(campaign.id)}
                  handleAddToCampaign={handleAddToCampaign}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
