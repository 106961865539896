import { withStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

export default withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFF',
    border: '0.5px solid #C8C8C8',
    borderRadius: '5px',
    fontSize: 11,
    paddingLeft: '15px',
    height: '20px',
    width: '100px',
    margin: '5px',
  },
}))(InputBase);
