import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';


// material-ui
import { yellow, grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


// project imports
import Avatar from 'ui-component/extended/Avatar';
import ExtendedChip from 'ui-component/extended/Chip';


// assets
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SellIcon from '@mui/icons-material/Sell';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import HttpsIcon from '@mui/icons-material/Https';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

import SkeletonTableRow from './SkeletonTableRow'
import SocialMediaIcon from '../Extra/SocialMediaIcon'

import { updateSelectedInfluencers, showPremiumFeatureDialog, updateUser } from 'actions/planner2'
import { checkProfilePermission, unlockProfile } from 'api_handlers/planner2'
import { fetchNewInfluencer } from 'api_handlers/planner2'
import { getDiscoveryInfluencersDetails } from 'api_handlers/planner2';

const getCategoryChips = (row) => {
    const categories = [];

    if (row.primary_category && row.primary_category.name && row.primary_category.name.length > 0) {
        categories.push(row.primary_category.name);
    }

    const categoryChips = categories.slice(0, 3).map((category, index) => (
        <Grid item key={index}><ExtendedChip label={category} sx={{color: 'secondary.main'}}/></Grid>
    ));

    if(!row.interest_categories) return categoryChips;


    row.interest_categories.forEach((category) => {
        categories.push(category);
    });
    

    if(categories.length <= 3) return categoryChips;

    return (
        <>
        {categoryChips}
        {<Grid item>
            <ExtendedChip label={`+${categories.length - 3}`} sx={{color: 'secondary.main'}} />
        </Grid>}
        </>
    )
}


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      '& .action-button': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  }));
  
  const ActionButton = styled(Button)(({ theme }) => ({
    visibility: 'hidden',
    opacity: 0,
    position: 'absolute',
    right: theme.spacing(2),
    top: '50%',
    transform: 'translateY(-50%)',
    transition: 'visibility 0s, opacity 0.5s linear',
  }));

const StyledHeaderColumn = ({name, Icon}) => {
    return (
    <TableCell sx={{ border: 1, borderColor: grey[200], borderTop: 'none' }}>
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={0}>
            <Grid item><Icon sx={{fontSize: 20, color: 'secondary.main'}}/></Grid>
            <Grid item sx={{mb:1}}><Typography fontWeight={"bold"} sx={{color: 'secondary.main'}} fontSize={'0.8rem'}>{name}</Typography></Grid>
        </Grid>
    </TableCell>
    )
}

const StyledTableCell = ({children}) => {
    return (
        <TableCell align="center" sx={{ border: 1, borderColor: grey[200] }} fontSize="0.8rem">
            <Typography fontWeight={"bold"} fontSize={'0.8rem'} sx={{textTransform: 'capitalize'}}>
                {children}
            </Typography>
        </TableCell>
    )
}


const UserList = ({influencers, loading, handleAddToCampaign}) => {
    const user = useSelector((state) => state.planner2.user);
    const selected = useSelector((state) => state.planner2.selectedInfluencers);
    const [updateStatus, setUpdateStatus] = React.useState('')

    let history = useHistory()
    const dispatch = useDispatch();

    const [profilePermissionMap, setProfilePermissionMap] = React.useState({});
    const [loaderMap, setLoaderMap] = React.useState({});

    const skeletonCount = Array.from({ length: 20 });
    

    const setSelected = (newSelected) => {
        dispatch(updateSelectedInfluencers(newSelected))
    }


    const onSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = influencers.map((n) => n.id);
            setSelected(new Set(newSelecteds));
            return;
        }
        setSelected(new Set());
    };

    const handleClick = (event, id) => {
        if (selected.has(id)) {
            const newSelected = new Set(selected);
            newSelected.delete(id);
            setSelected(newSelected);
        } else {
            setSelected(new Set(selected).add(id));
        }
    };

    const fetchDetails = async (getId) => {
        if (getId) {
            setLoaderMap(prevLoaderMap => {
                console.log("Previous loaderMap:", prevLoaderMap);
                const newLoaderMap = { ...prevLoaderMap, [getId]: true };
                console.log("Updated loaderMap:", newLoaderMap);
                return newLoaderMap;
            });
            console.log("fetch details", loaderMap)

            try {
                const response = await getDiscoveryInfluencersDetails(getId);

                // if (response?.update_status === 'updating') {
                //     setUpdateStatus('updating');
                // }
                // if (response?.update_status === 'updated') {
                //     setUpdateStatus('updated');
                // }
                return response;
            } catch (error) {
                console.error("Error fetching details:", error);
                setLoaderMap(prevLoaderMap => {
                    console.log("Previous loaderMap:", prevLoaderMap);
                    const newLoaderMap = { ...prevLoaderMap, [getId]: false };
                    console.log("Updated loaderMap:", newLoaderMap);
                    return newLoaderMap;
                });
            } finally {
                setLoaderMap(prevLoaderMap => {
                    console.log("Previous loaderMap:", prevLoaderMap);
                    const newLoaderMap = { ...prevLoaderMap, [getId]: false };
                    console.log("Updated loaderMap:", newLoaderMap);
                    return newLoaderMap;
                });
            }
        }
    };


    const handleShowDetails = async (row) => {
        setLoaderMap(prevLoaderMap => {
            console.log("Previous loaderMap:", prevLoaderMap);
            const newLoaderMap = { ...prevLoaderMap, [row.id]: true };
            console.log("Updated loaderMap:", newLoaderMap);
            return newLoaderMap;
        });
        let response = await fetchDetails(row.id);
        if (row.url && row.url.indexOf('https://www.instagram.com/None') > -1) {
            row.url = response.url;
        }
        console.log("2 loader mapp", loaderMap[row.id])
        const isLoading = loaderMap[row.id];
        if (!isLoading) {
            if (profilePermissionMap[row.id]) {
                openProfileHandler(row);
            } else {
                unlockProfileHandler(row);
            }
        } else {
            setLoaderMap(prevLoaderMap => {
                console.log("Previous loaderMap:", prevLoaderMap);
                const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
                console.log("Updated loaderMap:", newLoaderMap);
                return newLoaderMap;
            });
        }
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const resp = await checkProfilePermission({
            payload: {
                profile_ids: influencers.map(inf => inf.id)
            }
        })
        setProfilePermissionMap(resp.profile_id_access_map)

    }, [influencers])

    const openProfileHandler = (row) => {
        dispatch(updateUser())
        setProfilePermissionMap((prevData) => ({
            ...prevData,
            [row.id]: true
        }))
        setLoaderMap(prevLoaderMap => {
            console.log("Previous loaderMap:", prevLoaderMap);
            const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
            console.log("Updated loaderMap:", newLoaderMap);
            return newLoaderMap;
        });
        window.open(
            `/planner/profile-details/${row.platform}/${
                row.platform === 'instagram'
                ? `${row.url.split('/')[3]}`
                : `${row.url.split('/')[4]}`
            }`,
            '_blank',
          )
    }

    const unlockProfileHandler = async (row) => {
        setLoaderMap(prevLoaderMap => {
            console.log("Previous loaderMap:", prevLoaderMap);
            const newLoaderMap = { ...prevLoaderMap, [row.id]: true };
            console.log("Updated loaderMap:", newLoaderMap);
            return newLoaderMap;
        });
        try {
            if(!row.audience_data_exist) {
                await fetchNewInfluencer({
                    payload: {
                      link: row.url,
                      "with-audience-data": true
                    },
                  });
            }

            const resp = await unlockProfile(row.id)
            if(resp?.detail === "Success") {
                openProfileHandler(row)
            } else {
                setLoaderMap(prevLoaderMap => {
                    console.log("Previous loaderMap:", prevLoaderMap);
                    const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
                    console.log("Updated loaderMap:", newLoaderMap);
                    return newLoaderMap;
                });
            }
        } catch(error) {
            setLoaderMap(prevLoaderMap => {
                console.log("Previous loaderMap:", prevLoaderMap);
                const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
                console.log("Updated loaderMap:", newLoaderMap);
                return newLoaderMap;
            });
            if (error && error.response && error.response.status === 403) {
                dispatch(showPremiumFeatureDialog(true));
            }
        }
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ border: 1, borderColor: grey[200], borderTop: 'none' }}>
                            <Checkbox
                                color="primary"
                                indeterminate={selected.size > 0 && selected.size < influencers.length}
                                checked={influencers.length > 0 && selected.size === influencers.length}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all desserts'
                                }}
                            />
                        </TableCell>
                        <StyledHeaderColumn name="Profile" Icon={AccountCircleIcon} />
                        {/* <StyledHeaderColumn name="Score" Icon={CheckCircleIcon} /> */}
                        <StyledHeaderColumn name="Followers" Icon={GroupIcon} />
                        <StyledHeaderColumn name="ER" Icon={GroupAddIcon} />
                        <StyledHeaderColumn name="Avg Views" Icon={RemoveRedEyeIcon} />
                        <StyledHeaderColumn name="Avg Engagement" Icon={GroupAddIcon} />
                        <StyledHeaderColumn name="Location" Icon={LocationOnIcon} />
                        <TableCell>
                            <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={0}>
                                <Grid item><SellIcon sx={{fontSize: 20, color: 'secondary.main'}}/></Grid>
                                <Grid item sx={{mb:1}}><Typography fontWeight={"bold"} sx={{color: 'secondary.main'}} fontSize={'0.8rem'}>Categories</Typography></Grid>
                            </Grid>
                        </TableCell>
                        <TableCell sx={{maxWidth: 0}} align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (skeletonCount.map((_, index) => (
                        <SkeletonTableRow key={index} />
                    ))) : null}

                    {influencers && influencers.length > 0 ? (
                        influencers.map((row, index) => {
                            const isItemSelected = selected.has(row.id);
                            return (
                            <StyledTableRow hover key={row.id}>
                                <TableCell sx={{pl: 2, border: 1, borderColor: grey[200]}}  padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                            // 'aria-labelledby': labelId
                                        }}
                                    />
                                </TableCell>
                                <TableCell onClick={(event) => handleClick(event, row.id)} sx={{ border: 1, borderColor: grey[200] }} >
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Avatar alt="User 1" src={row.picture} />
                                        <Stack>
                                            <Stack direction={'row'} alignItems="center" justifyContent={'center'} spacing={0.10}>
                                                <Typography variant="subtitle1" fontWeight={"bold"} fontSize={'0.8rem'}>{row.name}</Typography>
                                                <Stack>
                                                    <SocialMediaIcon platform={row.platform} url={row.url} />
                                                </Stack>
                                            </Stack>
                                            <Typography variant="subtitle2" noWrap fontWeight={"bold"} fontSize={'0.8rem'}>
                                                {row.language}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </TableCell>
                                {/* <TableCell sx={{ border: 1, borderColor: grey[200] }}>
                                    <ExtendedChip
                                        avatar={<CheckCircleIcon sx={{ height: 18 }} />}
                                        label={`SPEED: ${row.speed_score}`}
                                        chipcolor="success"
                                        sx={{
                                            '& .MuiChip-label': {
                                                fontWeight: 800,
                                                fontSize: '0.7rem',
                                                paddingLeft: '6px',
                                                paddingRight: '6px'
                                            },
                                            '& .MuiChip-avatar': {height: 15, marginLeft: 0},
                                            height: 25
                                        }}
                                    />
                                </TableCell> */}
                                <StyledTableCell>{row.followers}</StyledTableCell>
                                <StyledTableCell>{row.engagement_rate} </StyledTableCell>
                                <StyledTableCell>{row.avg_views} </StyledTableCell>
                                <StyledTableCell>{row.prediction?.enagaged_users_display} </StyledTableCell>
                                <StyledTableCell>{row.city} </StyledTableCell>
                                <TableCell sx={{maxWidth: 200}}>
                                    <Grid container spacing={1}>
                                        {getCategoryChips(row)}
                                    </Grid>
                                </TableCell>
                                <TableCell sx={{maxWidth: 0}} align="right">
                                    <Grid container spacing={1}>
                                        <ActionButton
                                            className="action-button"
                                            variant="contained"
                                            color="primary"
                                            onClick={(event) => {
                                                if(user.premium_user) handleAddToCampaign([row.id])
                                                else dispatch(showPremiumFeatureDialog(true));
                                                
                                            }}
                                            sx={{right: profilePermissionMap[row.id] ? 135 : 160}}
                                            startIcon={<WorkspacePremiumIcon />}
                                        >
                                            Add to Campaign
                                        </ActionButton>
                                        <ActionButton
                                            className="action-button"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                handleShowDetails(row)
                                            }}
                                            style={{ color: loaderMap[row.id] ? 'black' : 'black' }}
                                            startIcon={
                                                loaderMap[row.id] ? 
                                                <CircularProgress size={20} sx={{ color: 'black' }}/> 
                                                : profilePermissionMap[row.id] ? 
                                                null 
                                                : <HttpsIcon />}
                                            disabled={loaderMap[row.id]}
                                        >
                                            {loaderMap[row.id] ? 'Loading' : 'Show Details' }
                                        </ActionButton>
                                    </Grid>
                                    
                                </TableCell>
                            </StyledTableRow>
                        )})
                    ) : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserList;
