import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import MainCard from 'ui-component/cards/MainCard';

// project imports
import UserList from './UserList';
import AddToCampaignModal from './AddToCampaignModal';

import { gridSpacing } from 'store/constant';
import { getPlannerDownload } from 'api_handlers/discovery';
import { showPremiumFeatureDialog, fetchInfluencersAction, updatePage} from 'actions/planner2';
import { updateFilters } from 'actions/planner-filters';

import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const scrollToTop = async () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto' // This enables smooth scrolling
    });
  }

// ==============================|| USER LIST STYLE 1 ||============================== //

const InfluencerList = ({
    influencers,
    totalResults,
    loading,
    // updateFilters,
}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.planner2.user);
    const page = useSelector((state) => state.planner2.page);
    const selectedInfluencers = useSelector((state) => state.planner2.selectedInfluencers);

    const [sortBy, setSortBy] = React.useState('followers');
    const [openAddToCampaign, setOpenAddToCampaign] = React.useState(false);

    const [addToCampaignInfluencers, setAddToCampaignInfluencers] = React.useState([]);
    // const [localPayload, setLocalPayload] = React.useState({...payload})

    useEffect(() => {
        dispatch(updateFilters('sortBy', sortBy, true ));
    }, [sortBy]);

    const handleDownload = () => {
        getPlannerDownload({
            selected_ids: Array.from(selectedInfluencers),
            objective: "",
            prediction: {},
            contacts: "not_req",
            selectedColumns: [],
        });
    }

    const handleAddToCampaign = (influencerIds) => {
        const infs = influencers.filter((inf) => influencerIds.includes(inf.id));
        setAddToCampaignInfluencers(infs);
        setOpenAddToCampaign(true);
    }
    
    return (
        <MainCard
            sx={{ 
                '& .MuiCardHeader-root': {
                    height: 70
                }
            }}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">INFLUENCERS ({totalResults})</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                            <InputLabel id="age-select">Sort by</InputLabel>
                            <Select
                                labelId="platform-select"
                                id="sortBy"
                                name="sortBy"
                                value={sortBy}
                                onChange={(event) => setSortBy(event.target.value)}
                                label="Sort By"
                            >
                                <MenuItem value={'followers'}>Followers</MenuItem>
                                <MenuItem value={'views'}>Avg Views</MenuItem>
                                <MenuItem value={'eng-rate'}>ER</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <UserList influencers={influencers} loading={loading} handleAddToCampaign={handleAddToCampaign} />
            <Grid item xs={12} sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Pagination count={Math.ceil(totalResults / 20)} color="primary" page={page} onChange={(e, updatedPage) => {
                            if(user.premium_user || updatedPage < 4) {
                                dispatch(updatePage(updatedPage))
                                dispatch(fetchInfluencersAction(updatedPage))
                                scrollToTop();
                            }
                            else dispatch(showPremiumFeatureDialog(true));
                        }} />
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={selectedInfluencers.size > 0 && !openAddToCampaign}
            >
                <Box sx={{ p: 2, bgcolor: '#111936', borderRadius: 1, minWidth: 600 }}>
                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={3}>
                        <Grid item xs={'auto'}> 
                            <Typography variant="h4" sx={{ color: '#ffffff' }}>
                                Selected {selectedInfluencers.size} influencers
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button fullWidth variant="contained" color='primary' onClick={() => {
                                if(user.premium_user) {
                                    handleAddToCampaign(Array.from(selectedInfluencers));
                                } else {
                                    dispatch(showPremiumFeatureDialog(true));
                                }
                            }} startIcon={<WorkspacePremiumIcon />}>
                                Add to Campaign
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button fullWidth variant="contained" color='primary' onClick={() => {
                                if(user.premium_user) {
                                    handleDownload();
                                } else {
                                    dispatch(showPremiumFeatureDialog(true));
                                }
                            }} startIcon={<WorkspacePremiumIcon />}>
                                Download
                            </Button>
                        </Grid>
                    </Grid>
                    
                </Box>
            </Snackbar>
            <AddToCampaignModal
                open={openAddToCampaign}
                setOpen={setOpenAddToCampaign}
                influencers={addToCampaignInfluencers}
            />
        </MainCard>
    );
};

export default InfluencerList;
